import React, { Suspense } from 'react';
import { useRoutes } from 'react-router-dom';

// layouts
import DefaultLayout from '../layouts/Default';
import VerticalLayout from '../layouts/Vertical';
import HorizontalLayout from '../layouts/Horizontal/';
// components
import PrivateRoute from './PrivateRoute';
import Root from './Root';

// constants
import { LayoutTypes } from '../constants';

// hooks
import { useRedux } from '../hooks';


// lazy load all the views
// auth
const Login = React.lazy(() => import('../pages/auth/Login'));
const Register = React.lazy(() => import('../pages/auth/Register'));
const Confirm = React.lazy(() => import('../pages/auth/Confirm'));
const ForgetPassword = React.lazy(() => import('../pages/auth/ForgetPassword'));
const LockScreen = React.lazy(() => import('../pages/auth/LockScreen'));
const Logout = React.lazy(() => import('../pages/auth/Logout'));
const Otp=React.lazy(()=>import('../pages/auth/Otp'));
const ConfirmPassword = React.lazy(()=>import('../pages/auth/ConfirmPass'))
const Reset = React.lazy(()=>import('../pages/auth/reset'))
const ChangePassword =React.lazy(()=>import('../pages/auth/ChangePassword'))
// dashboards
const DashBoard1 = React.lazy(() => import('../pages/dashboards/DashBoard1/'));
const MainDashboard = React.lazy(()=>import ('../pages/dashboards/main-dashboard/main-dashboard'));
// apps

const CalendarApp = React.lazy(() => import('../pages/apps/Calendar'));
const GenerateReport = React.lazy(() => import('../pages/apps/Generate_Reports'));
const UploadTicket = React.lazy(() => import('../pages/apps/Upload_Ticket'));
const Automation = React.lazy(() => import('../pages/apps/Automation_Tool'));
const Advice = React.lazy(()=>import('../pages/apps/Advice'));
const Alerts1 = React.lazy(() => import('../pages/apps/Awarness'));
const ChangeRole = React.lazy(() => import('../pages/apps/Change_role'));
const ChatApp = React.lazy(() => import('../pages/apps/Chat'));
const Inbox = React.lazy(() => import('../pages/apps/Email/Inbox'));
const Bug = React.lazy(() => import('../pages/apps/Tasks/Bug'));
const BugDetail = React.lazy(() => import('../pages/apps/Tasks/BugTicket'));
const Breach = React.lazy(() => import('../pages/apps/Tasks/Breach'));
const BreachDetail = React.lazy(() => import('../pages/apps/Tasks/BreachTicket'));
const Projects = React.lazy(() => import('../pages/apps/Projects'));
const Memberss = React.lazy(()=>import('../pages/apps/Members'));
const User = React.lazy(()=>import('../pages/apps/User'));
const UploadAssets = React.lazy(()=>import('../pages/apps/Inventory/upload'))
const Assets = React.lazy(()=>import('../pages/apps/Inventory/assets'))
const List = React.lazy(() => import('../pages/apps/Contacts/List'));
const Profile = React.lazy(() => import('../pages/apps/Contacts/Profile'));
const Alert = React.lazy(()=>import('../pages/apps/Tasks/Alert'))
const Alerts = React.lazy(()=>import('../pages/apps/Tasks/Alerts'))
const AssetDetails = React.lazy(()=>import ('../pages/apps/Inventory/asset-details/asset-details'))
// strategy
const strategy = React.lazy(() => import('../pages/apps/Strategy'));
const Api_Documentation = React.lazy(() => import('../pages/apps/Api_Documentation'));
const API_Keys = React.lazy(()=>import('../pages/apps/API_Key'))
const Policies = React.lazy(()=>import('../pages/apps/Policies/allPolicies'))
const NewPolicy = React.lazy(()=>import('../pages/apps/Policies/newPolicy'))
const Policy = React.lazy(()=>import('../pages/apps/Policies/Policy'))


// extra pages
const Starter = React.lazy(() => import('../pages/other/Starter'));
const Pricing = React.lazy(() => import('../pages/other/Pricing'));
const Timeline = React.lazy(() => import('../pages/other/Timeline'));
const Invoice = React.lazy(() => import('../pages/other/Invoice'));
const FAQ = React.lazy(() => import('../pages/other/FAQ'));
const Gallery = React.lazy(() => import('../pages/other/Gallery'));
const Error404 = React.lazy(() => import('../pages/other/Error404'));
const Error500 = React.lazy(() => import('../pages/other/Error500'));
const Maintenance = React.lazy(() => import('../pages/other/Maintenance'));
const ComingSoon = React.lazy(() => import('../pages/other/ComingSoon'));

// base ui
const Buttons = React.lazy(() => import('../pages/uikit/Buttons'));
const Cards = React.lazy(() => import('../pages/uikit/Cards'));
const Avatars = React.lazy(() => import('../pages/uikit/Avatars'));
const TabsAccordions = React.lazy(() => import('../pages/uikit/TabsAccordions'));
const Notifications = React.lazy(() => import('../pages/uikit/Notifications'));
const Modals = React.lazy(() => import('../pages/uikit/Modals'));
const Progress = React.lazy(() => import('../pages/uikit/Progress'));
const Offcanvases = React.lazy(() => import('../pages/uikit/Offcanvases'));
const Placeholders = React.lazy(() => import('../pages/uikit/Placeholders'));
const Spinners = React.lazy(() => import('../pages/uikit/Spinners'));
const Images = React.lazy(() => import('../pages/uikit/Images'));
const Carousel = React.lazy(() => import('../pages/uikit/Carousel'));
const EmbedVedio = React.lazy(() => import('../pages/uikit/EmbedVideo'));
const Dropdowns = React.lazy(() => import('../pages/uikit/Dropdowns'));
const PopoversAndTooltips = React.lazy(() => import('../pages/uikit/PopoversAndTooltips'));
const GeneralUI = React.lazy(() => import('../pages/uikit/GeneralUI'));
const Typography = React.lazy(() => import('../pages/uikit/Typography'));
const Grid = React.lazy(() => import('../pages/uikit/Grid'));

// widgets
const Widgets = React.lazy(() => import('../pages/uikit/Widgets'));

// extended ui
const RangeSliders = React.lazy(() => import('../pages/uikit/RangeSlider'));
// const NestableList = React.lazy(() => import('../pages/uikit/NestableList'));
const SweetAlerts = React.lazy(() => import('../pages/uikit/SweetAlerts'));
const Tourpage = React.lazy(() => import('../pages/uikit/TourPage'));
const TreeViewExample = React.lazy(() => import('../pages/uikit/TreeView'));

// icons
const FeatherIcons = React.lazy(() => import('../pages/icons/FeatherIcons'));
const MDIIcons = React.lazy(() => import('../pages/icons/MDIIcons'));
const Dripicons = React.lazy(() => import('../pages/icons/DripiIcons'));
const FontAwesomeIcons = React.lazy(() => import('../pages/icons/FontAwesomeIcons'));
const ThemifyIcons = React.lazy(() => import('../pages/icons/ThemifyIcons'));

// forms
const GeneralElements = React.lazy(() => import('../pages/forms/Basic'));
const FormAdvanced = React.lazy(() => import('../pages/forms/Advanced'));
const Validation = React.lazy(() => import('../pages/forms/Validation'));
const FormWizard = React.lazy(() => import('../pages/forms/Wizard'));
const FileUpload = React.lazy(() => import('../pages/forms/FileUpload'));
const EditAsset =React.lazy(()=>import('../pages/apps/Inventory/edit-asset/edit-asset'));
const EditDevice =React.lazy(()=>import('../pages/apps/Inventory/edit-device/edit-device'));
const Editors = React.lazy(() => import('../pages/forms/Editors'));
const AddAsset = React.lazy(() => import('../pages/apps/asset-management/create-new-asset'));
const AddDevice =React.lazy(()=>import('../pages/apps/Inventory/new-device/newdevice'));
const AddSection =React.lazy(()=>import('../pages/apps/Inventory/new-section/newsection'));
const Section = React.lazy(()=>import('../pages/apps/Inventory/section/'));
const AddVendor =React.lazy(()=> import ('../pages/apps/Inventory/add-vendor/add-vendor'));
// tables
const BasicTable = React.lazy(() => import('../pages/tables/BasicTable'));
const AdvancedTable = React.lazy(() => import('../pages/tables/AdvancedTable'));

// charts
const ApexChart = React.lazy(() => import('../pages/chart/ApexChart'));
const ChartJs = React.lazy(() => import('../pages/chart/ChartJs'));

// maps
const GoogleMaps = React.lazy(() => import('../pages/maps/GoogleMaps'));
const VectorMaps = React.lazy(() => import('../pages/maps/VectorMaps'));

// lamding
const Landing = React.lazy(() => import('../pages/Landing'));

const loading = () => <div className=""></div>;
const dashboardroles=['0','2','1']
const projectroles=['1','2','0']

// 0 superadmin, 1 admin, 2 manager, 3 tester, 4 member
const secureroles = ['0']
type LoadComponentProps = {
  component:  React.LazyExoticComponent<() => JSX.Element>;
};

const LoadComponent = ({ component: Component }: LoadComponentProps) => (
  <Suspense fallback={loading()}>
    <Component />
  </Suspense>
);

const AllRoutes = () => {
  const { appSelector } = useRedux();

  const { layout } = appSelector((state) => ({
    layout: state.Layout,
  }));

  const getLayout = () => {
    let layoutCls: React.ComponentType = VerticalLayout;

    switch (layout.layoutType) {
      case LayoutTypes.LAYOUT_HORIZONTAL:
        layoutCls = HorizontalLayout;
        break;
      default:
        layoutCls = VerticalLayout;
        break;
    }
    return layoutCls;
  };
  let Layout = getLayout();

  return useRoutes([
    { path: '/', element: <Root /> },
    {
      // public routes
      path: '/',
      element: <DefaultLayout />,
      children: [
            { path: 'login', element: <LoadComponent component={Login} /> },
            { path: 'register', element: <LoadComponent component={Register} /> },
            { path: 'confirm', element: <LoadComponent component={Confirm} /> },
            { path: 'forget-password', element: <LoadComponent component={ForgetPassword} /> },
            { path: 'lock-screen', element: <LoadComponent component={LockScreen} /> },
            { path: 'logout', element: <LoadComponent component={Logout} /> },
            { path: 'otp', element: <LoadComponent component={Otp} /> },
            // { path: 'confirm/:uid/:token', element: <LoadComponent component={ConfirmPassword} /> },
            { path: 'confirm/:uid/:token/', element: <LoadComponent component={Reset} /> },
            // { path: 'reset/:uid', element: <LoadComponent component={Reset} /> },
            { path: 'ChangePassword', element: <LoadComponent component={ChangePassword} /> },



        {
          path: 'error-404',
          element: <LoadComponent component={Error404} />,
        },
        {
          path: 'error-500',
          element: <LoadComponent component={Error500} />,
        },
        {
          path: 'maintenance',
          element: <LoadComponent component={Maintenance} />,
        },
        {
          path: 'coming-soon',
          element: <LoadComponent component={ComingSoon} />,
        },
        {
          path: 'landing',
          element: <LoadComponent component={Landing} />,
        },
      ],
    },
    {
      // auth protected routes
      // admin - super admin - managers roles
      path: '/',
      element: <PrivateRoute component={Layout} roles={dashboardroles} />,
      children: [
        {
          path: 'dashboard',
          element: <LoadComponent component={DashBoard1} />,
        },
          {
          path: 'main-dashboard',
          element: <LoadComponent component={MainDashboard} />,
        },
        {
          path: 'changerole',
          element:<LoadComponent component={ChangeRole} />,
        },
        {
          path: 'assets-upload',
          element:<LoadComponent component={UploadAssets} />,
        },{
          path: 'assets',
          element:<LoadComponent component={Assets} />,
        },
        {
          path: 'strategy',
          element: <LoadComponent component={strategy} />,
        },
        {
           path: 'api-keys',
           element:<LoadComponent component={API_Keys} />,
         },
        {
           path: 'policies/:strategy_id',
           element:<LoadComponent component={Policies} />,
         },
        {
           path: 'policies/:strategy_id/add-policy',
           element:<LoadComponent component={NewPolicy} />,
         },
        {
           path: 'policies/:strategy_id/:policy_id',
           element:<LoadComponent component={Policy} />,
         },

      ],
    },
    {
      // admin - super admin-tester routes
      path: '/',
      element: <PrivateRoute roles={['0','1','3']} component={Layout} />,
      children: [
        {
          path: 'automation',
          element:<LoadComponent component={Automation} />,
        },
        {
          path: 'assets/:section_id/:device_id/add-asset',
          element:<LoadComponent component={AddAsset} />,

        },
           {
          path: 'assets/section/:section_id/:device_id/',
          element:<LoadComponent component={Assets} />,

        },
        {
          path: 'assets/section/:section_id/:device_id/:asset_id/',
          element: <LoadComponent component={AssetDetails}/>
        },
        {
          path: 'assets/add-section',
          element: <LoadComponent component={AddSection}/>
        },
          {
          path: 'assets/section/:section_id',
          element: <LoadComponent component={Section}/>
        },
        {
          path: 'assets/section/:section_id/add-device',
          element: <LoadComponent component={AddDevice}/>
        },
        {
          path: 'assets/section/:section_id/:device_id/edit-device',
          element: <LoadComponent component={EditDevice}/>
        },
        {  path: 'assets/add-vendor',
          element: <LoadComponent component={AddVendor}/>
        },
        {
          path: 'assets/section/:section_id/:device_id/:asset_id/edit-asset',
          element: <LoadComponent component={EditAsset}/>
        }
      ],
    },
    {
      // admin - super admin- routes
      path: '/',
      element: <PrivateRoute roles={secureroles} component={DefaultLayout} />,
      children: [
         { path: 'docs', element: <LoadComponent component={Api_Documentation} /> },

      ],
    },
    {
      // admin - super admin- manager - tester routes
      path: '/',
      element: <PrivateRoute roles={['0','1','3','2']} component={Layout} />,
      children: [
        {
          path: 'calendar',
          element: <LoadComponent component={CalendarApp} />,
        }
      ],
    },
    {
      // admin - super admin- manager - tester routes
      path: '/',
      element: <PrivateRoute roles={['0','1','3','2','5','6','7']} component={Layout} />,
      children: [
        {
          path: 'generate-report',
          element:<LoadComponent component={GenerateReport} />,
        }
        ],
    },
    {
      // admin - super admin- manager routes
      path: '/',
      element: <PrivateRoute roles={['0','1','2']} component={Layout} />,
      children: [
        {
          path: 'projects',
          element: <LoadComponent component={Projects} />,
          
        },
        {
          path:'projects/:id/members',
              element:<LoadComponent component={Memberss}/>
        },
        {
          path:'users',
              element:<LoadComponent component={User}/>
        }
      ],
    },
    {
      // admin - super admin- tiers - manager routes
      path: '/',
      element: <PrivateRoute requiredPermission={'alert'} roles={['0','1','2','5','6','7']} component={Layout} />,
      children: [
        {
          path: 'tickets/soc',
            element: <LoadComponent component={Alerts} />,
        },
        {
          path: 'tickets/soc/:project/:id',
          element: <LoadComponent component={Alert} />,
        }
      ],
    },
    {
      // all roles routes
      path: '/',
      element: <PrivateRoute roles={['0','1','2','3','4','5','6','7']} component={Layout} />,
      children: [
        {
          path: 'guard-advises',
          element: <LoadComponent component={Alerts1} />,
        },
        {
          path:'guard-advises/:id/advice',
              element:<LoadComponent component={Advice}/>
        },
      ],
    },
    {path: '/',
      element: <PrivateRoute requiredPermission={'breach'}  roles={['0','1','2','3','4']}  component={Layout} />,
      children: [
       {
              path: 'tickets/breach',
              element: <LoadComponent component={Breach} />,
            },
            {
              path: 'tickets/breach/:project/:id',
              element: <LoadComponent component={BreachDetail} />,
            },
          ]
    },
    {
      // bug tickets : super admin - admin - manager - tester - member
      path: '/',
      element: <PrivateRoute requiredPermission={'bug'}  roles={['0','1','2','3','4']}  component={Layout} />,
      children: [
          {
              path: 'tickets/bug',
                element: <LoadComponent component={Bug} />,
            },
            {
              path: 'tickets/bug/:project/:id',
              element: <LoadComponent component={BugDetail} />,
            },
            

        

        {
          path: 'widgets',
          element: <LoadComponent component={Widgets} />,
        },
        {
          path: 'icons',
          children: [
            {
              path: 'feather',
              element: <LoadComponent component={FeatherIcons} />,
            },
            {
              path: 'mdi',
              element: <LoadComponent component={MDIIcons} />,
            },
            {
              path: 'dripicons',
              element: <LoadComponent component={Dripicons} />,
            },
            {
              path: 'font-awesome',
              element: <LoadComponent component={FontAwesomeIcons} />,
            },
            {
              path: 'themify',
              element: <LoadComponent component={ThemifyIcons} />,
            },
          ],
        },
        {
          path: 'forms',
          children: [
            {
              path: 'basic',
              element: <LoadComponent component={GeneralElements} />,
            },
            {
              path: 'advanced',
              element: <LoadComponent component={FormAdvanced} />,
            },
            {
              path: 'validation',
              element: <LoadComponent component={Validation} />,
            },
            {
              path: 'wizard',
              element: <LoadComponent component={FormWizard} />,
            },
            {
              path: 'upload',
              element: <LoadComponent component={FileUpload} />,
            },
            // {
            //   path: 'editors',
            //   element: <LoadComponent component={Editors} />,
            // },
          ],
        },
        {
          path: 'charts',
          children: [
            {
              path: 'apex',
              element: <LoadComponent component={ApexChart} />,
            },
            {
              path: 'chartjs',
              element: <LoadComponent component={ChartJs} />,
            },
          ],
        },
      ],
    },
  ]);
};

export { AllRoutes };
