import React, { createContext, useContext, useEffect, useState } from "react";
import axios from "axios";

// Define the shape of the context data
interface AssetContextType {
  data: any[];
  setData: (data: any[]) => void;
  loading: boolean;
  error: string | null;
  refetch: () => void;
}

// Create the context
const AssetContext = createContext<AssetContextType | undefined>(undefined);

// Define the provider component
export const AssetProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const [data, setData] = useState<any[]>([]);
  const [loading, setLoading] = useState<boolean>(true);
  const [error, setError] = useState<string | null>(null);

  const fetchData = async () => {
    setLoading(true);
    setError(null);
    try {
      const response = await axios.get("/inventory/sections/"); // Replace with your API endpoint
      setData(response.data.results);
    } catch (err: any) {
      setError(err.message || "Failed to fetch data");
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  return (
    <AssetContext.Provider value={{setData, data, loading, error, refetch: fetchData }}>
      {children}
    </AssetContext.Provider>
  );
};

export const useAssets = (): AssetContextType => {
  const context = useContext(AssetContext);
  if (!context) {
    throw new Error("useDataContext must be used within a DataProvider");
  }
  return context;
};
